import React, { useEffect, useState, useRef } from 'react';
import { EuiButton, EuiFieldText, EuiTextArea, EuiForm, EuiFormRow, EuiListGroup, EuiText, EuiFlexGroup, EuiFlexItem, EuiLink, EuiButtonIcon, EuiButtonGroup, EuiModal, EuiTable, EuiTableHeader, EuiTableHeaderCell, EuiTableBody, EuiTableRow, EuiTableRowCell, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiSpacer } from '@elastic/eui';
import '../App.css';
import Header from '../components/Header';
import RoomSettings from '../components/roomSettings';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import { encryptData, decryptData } from '../components/Encryption';
import config from '../config/config';
import notificationSound from '../assets/the-notification-email-143029.mp3';
import newuserSound from '../assets/icq-notification-sound-message-received-on-icq.mp3';
import DOMPurify from 'dompurify';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data'
import Cookies from 'js-cookie';

let defaultRoomSettings = {
  backgroundColor: "black",
  textColor: "white",
  fontSize: 16,
  fontFamily: 'Arial',
  fontWeight: 'normal',
  fontStyle: 'normal',
  roomName: '',
  roomLimit: 100,
  autoBlur: true,
  blurTime: 30
}

function App() {
  const { updatePage, pendingMessages, roomSettingsModal, userGUID, updateUserGUID, updateUserData, chatSettings, updateChat, updateChatUsers, updateSocket, socket, isMobileDevice, showFileChooser, updateShowFileChooser, updateChatSettings, updateRoomSettingsModal, updatePendingMessages } = useGlobalContext();
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const [connected, setConnected] = useState(false);
  const [encryptionKey, setEncryptionKey] = useState();
  const [iv] = useState(config.encryption.iv);
  const room = window.location.pathname.split('/')[1];
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const ws = useRef(null);
  const [image, setImage] = useState(null);
  const notificationAudio = new Audio(notificationSound);
  notificationAudio.volume = 0.3; // 50% volume
  const newuserAudio = new Audio(newuserSound);
  newuserAudio.volume = 0.3; // 50% volume
  const [isTyping, setIsTyping] = useState(false);
  const [userIsTyping, setUserIsTyping] = useState(false);
  const typingTimeoutId = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showReactionPicker, setShowReactionPicker] = useState(false);
  const [roomSettings, setRoomSettings] = useState(defaultRoomSettings);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showPrivacy, setShowPrivacy] = useState(!Cookies.get('intropriv')); // Check if cookie is set
  //const [showFileChooser, setShowFileChooser] = useState(false);
  const [downloads, setDownloads] = useState([]);
  const messageFormContainerRef = useRef(null);

  const handleShowPrivacy = () => {
    Cookies.set('intropriv', 'true', { expires: 365 }); // Set the cookie to acknowledge the warning
    setShowPrivacy(false); // Hide the callout after acknowledgment
  };

  const addEmoji = e => {
    let emoji = e.native;
    setInput(input + emoji);
  };

  const addReaction = async (e) => {
    let emoji = e.unified;
    console.log(e, selectedMessage, emoji);
    //let encryptedMessage = await encryptData(emoji, encryptionKey, iv);
    ws.current.send(JSON.stringify({ type: 'react', id: selectedMessage, message: btoa(emoji) }));
    setShowReactionPicker(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (messageFormContainerRef.current) {
        const windowHeight = window.innerHeight;
        messageFormContainerRef.current.style.top = `${windowHeight}px`;
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
  
    window.addEventListener('resize', handleResize);
  
    // Clean up function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
      const intervalId = setInterval(() => {
        if(roomSettings.autoBlur){          
          const now = Date.now();
          const threeMinutesAgo = now - 3 * 60 * 1000;
          const secondsAgo = now - parseInt(roomSettings.blurTime) * 1000;
      
          setMessages(messages => messages.map(message => {
            const messageTime = new Date(message.timestamp).getTime();  
            if (messageTime < secondsAgo && message.blur === false) {
              return { ...message, blur: true };
            } else {
              return message;
            }
          }));
        }
      }, 2500);
      return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Shift') {
        document.body.style.cursor = 'copy'; // Change the cursor when the Shift key is pressed
      }
    };
  
    const handleKeyUp = (event) => {
      if (event.key === 'Shift') {
        document.body.style.cursor = 'default'; // Change the cursor back when the Shift key is released
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    updatePage("chat");
    const fullUrl = window.location.href;

    // Split the URL by '#' to get the parts before and after the '#' character
    const urlParts = fullUrl.split('#');
    let secretKey = urlParts[1];
    if(!secretKey) { secretKey = "secret"; }
    //console.log(secretKey);
    setEncryptionKey(secretKey);

    let secret = encryptionKey || secretKey;

    //console.log(userGUID)
    ws.current = new WebSocket(`${protocol}//${window.location.host}/${room}/${userGUID}`);
    updateChat(room);

    ws.current.onopen = () => {
      updateSocket(true);
      console.log('Connected to the WebSocket server');
      setReconnectAttempts(0); // Reset the number of reconnection attempts
    };

    ws.current.onmessage = async (event) => {
      let parsedMessage = JSON.parse(event.data);

      if (parsedMessage.type === 'file') {
        const fileContent = await decryptData(parsedMessage.fileContent, secret, iv);
        const fileName = parsedMessage.fileName;
        const fileLink = document.createElement('a');
        const fileSize = parsedMessage.fileSize;
        fileLink.href = `data:application/octet-stream;base64,${fileContent}`;
        fileLink.download = fileName;
        fileLink.textContent = `Download ${fileName}`;
        //document.body.appendChild(fileLink);

        if(parsedMessage.guid !== userGUID) {
          setDownloads([...downloads, {link: fileLink, name: fileName, size: fileSize}]);
          setMessages(prevMessages => [...prevMessages, {id: parsedMessage.id, guid: parsedMessage.guid, message: `File: ${fileName} was shared. View the downloads section to accept.`, type: 'system'}]);
        }else{
          setMessages(prevMessages => [...prevMessages, {id: parsedMessage.id, guid: parsedMessage.guid, message: `File: ${fileName} was shared.`, type: 'system'}]);
        }
        return;
      }

      if (parsedMessage.type === 'userTyping') {
        if(parsedMessage.guid !== userGUID) {
          setUserIsTyping(true);
          if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }
        return;
      } else if (parsedMessage.type === 'userTypingEnd') {
        if(parsedMessage.guid !== userGUID) {
          setUserIsTyping(false);
        }
        return;
      }

      if(parsedMessage.type === 'connect' || parsedMessage.type === 'disconnect') {
        //console.log(parsedMessage)
        updateChatUsers(parsedMessage.users);
        parsedMessage.blur = null;
        parsedMessage.guid = 'system-message';

        if(parsedMessage.type === 'connect') {
          try{
            newuserAudio.play();
          }catch(e){}
        }
      }

      if(parsedMessage.type === 'reaction') {
        parsedMessage.message = parsedMessage.message;
        //let reaction = await decryptData(parsedMessage.message, secret, iv);
        let emoji = String.fromCodePoint('0x' + parsedMessage.message);
        let messageId = parsedMessage.id;
        //console.log(emoji);
        // Parse through the current messages looking for the one with this id and adding the reactions
        setMessages(prevMessages => prevMessages.map(message => {
          if (message.id === messageId) {
            message.reactions.push(emoji);
          }
          return message;
        }));
        return;
      }

      if(parsedMessage.type === 'message') {
        parsedMessage.message = atob(parsedMessage.message);
        //console.log("decrypt", encryptionKey || secretKey, iv, parsedMessage.message);
        parsedMessage.message = await decryptData(parsedMessage.message, secret, iv);
        parsedMessage.blur = false;
        parsedMessage.reactions = [];
      }

      if(parsedMessage.type === 'roomSettings') {
        let roomSettings = JSON.parse(atob(parsedMessage.message));
        //console.log(roomSettings);
        setRoomSettings(roomSettings);
        updateChatSettings(roomSettings);
        return;
      }

      if(parsedMessage.type === 'clear') {
        setMessages([{guid: 'system-message', message: 'Chat has been cleared by another user.', type: 'system'}]);
        return;
      }

      if(parsedMessage.type === 'endchat') {
        window.location.href = '/';
        return;
      }

      if(parsedMessage.type === 'commandresponse') {
        parsedMessage.message = atob(parsedMessage.message);
      }

      // If my guid is in the parsedMessage, replace it with 'You'
      if (parsedMessage.message.includes(userGUID)) {
        parsedMessage.message = parsedMessage.message.replace(userGUID, 'You').replace('has', 'have');
      }

      setMessages(prevMessages => [...prevMessages, parsedMessage]);
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      if (!document.hasFocus()) {
        try{
          updatePendingMessages(pendingMessages + 1);
          notificationAudio.play();
        }catch(e){}
      }else{
        updatePendingMessages(0); 
      }

    };

    ws.current.onclose = () => {
      updateSocket(false);
      console.log('Disconnected from the WebSocket server');
      let parsedMessage = {guid: 'system-message', message: 'You have been disconnected from the server. Please click Reconnect', type: 'disconnect'};
      setMessages(prevMessages => [...prevMessages, parsedMessage]);
      if (ws.current.readyState === WebSocket.CLOSED && reconnectAttempts < 5) { // Adjust the limit as needed
        console.log('Attempting to reconnect...');
        setTimeout(() => {
          ws.current = new WebSocket(`${protocol}//${window.location.host}/${room}`);
          setReconnectAttempts(reconnectAttempts + 1);
        }, 3000); // Wait 3 seconds before attempting to reconnect
      }
    };

    ws.current.onerror = (error) => {
      updateSocket(false);
      console.error('WebSocket error:', error);
      let parsedMessage = {guid: 'system-message', message: error.toString(), type: 'error'};
      setMessages(prevMessages => [...prevMessages, parsedMessage]);
    };

    return () => {
      updateSocket(false);
      ws.current.close();
    };
  }, [protocol, room]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  
    if (!isTyping && e.target.value && e.target.value[0] !== '/') {
      setIsTyping(true);
      ws.current.send(JSON.stringify({ type: 'typing', guid: userGUID }));
    }

    if(e.target.value === '') {
      setIsTyping(false);
      ws.current.send(JSON.stringify({ type: 'stopTyping', guid: userGUID }));
    }
  
    if (typingTimeoutId.current) {
      clearTimeout(typingTimeoutId.current);
    }
  
    typingTimeoutId.current = setTimeout(() => {
      setIsTyping(false);
      ws.current.send(JSON.stringify({ type: 'stopTyping', guid: userGUID }));
    }, 2500);
  };

  const handleFileChange = async (event) => {
    setFile(event.target.files[0]);
  };

  const handleSendFile = () => {
    if (!file) {
      alert('Please select a file to send');
      return;
    }

    const MAX_FILE_SIZE = 10485760; // 10 MB
    if (file.size > MAX_FILE_SIZE) {
      alert('File size exceeds the maximum limit of 10 MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const fileContent = reader.result.split(',')[1]; // Extract base64 content
      const message = JSON.stringify({
        type: 'file',
        fileName: file.name,
        fileContent: await encryptData(fileContent, encryptionKey, iv),
        fileSize: file.size,
        guid: userGUID,
      });

      ws.current.send(message);
    };

    reader.readAsDataURL(file);
    updateShowFileChooser(false);
  };

  const handleSend = async (event) => {
    event.preventDefault();
    let encryptedMessage;
    let defaultType = "message";
    let sendCommand = false;

    // If first char of input is "/", then it is a command
    if(input[0] === "/") {
      sendCommand = true;
      defaultType = "command";
      encryptedMessage = input.substring(1);
    }else{
      encryptedMessage = await encryptData(input, encryptionKey, iv);
    }

    if (input.trim()) {
      //console.log("encrypt", encryptionKey, iv, input);
      const message = {
        guid: userGUID,
        message: btoa(encryptedMessage),
        type: defaultType
      };
      setIsTyping(false);
      setShowEmojiPicker(false);
      ws.current.send(JSON.stringify({ type: 'stopTyping', guid: userGUID }));
      ws.current.send(JSON.stringify(message));
      setInput('');
      
    }
  };

  const parseMessage = (message) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    message = message.replace(urlPattern, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
    message = message.replace(/\n/g, '<br />'); // replace line breaks with <br />
  
    // Sanitize the message, only allow <br /> and <a> tags
    const cleanMessage = DOMPurify.sanitize(message, { ALLOWED_TAGS: ['br', 'a', 'img'] });
    return cleanMessage;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Message copied to clipboard');
    });
  };

  const handleDeleteMessage = (event, messageId) => {
    if (event.shiftKey) {
      setMessages(messages => messages.filter(message => message.id !== messageId));
    }
  };

  const handleReactionPicker = (event, messageId) => {
    setSelectedMessage(messageId);
    setShowReactionPicker(true);
  };

  return (
    <>
      <div className="container eui-scrollBar" style={{backgroundColor: roomSettings.backgroundColor, fontSize: roomSettings.fontSize, fontFamily: roomSettings.fontFamily}}>
        <Header nav={true} />
          <EuiListGroup className="message-list full-width">
            {messages.map((message, index) => (
              <div
              id={message.id}
              key={index}
              ref={index === messages.length - 1 ? messagesEndRef : null}
              className={
                (message.type === 'connect' 
                  ? 'system' 
                  : message.guid === userGUID 
                    ? 'my-message' 
                    : message.guid === 'system-message' 
                      ? 'system' 
                      : 'incoming-message') + (message.blur == true ? ' blur' : '') + ' message'
                }
              onClick={(event) => handleDeleteMessage(event, message.id)}
              >
                {message.type === 'message'
                  ? (
                    <React.Fragment>
                      <span className='message-user' >{message.guid === userGUID ? 'You' : message.guid.split('-')[0]}:</span>
                      <span className='message-content' dangerouslySetInnerHTML={{__html: parseMessage(message.message)}} />
                    </React.Fragment>
                  )
                  : message.type === 'image'
                    ? <img src={message.message} alt="Shared content" />
                    : message.message}
                    <div className='message-action'>
                      {message.type === 'message' && <><EuiButtonIcon onClick={() => copyToClipboard(message.message)} iconType="copy" aria-label="Copy" /> <EuiButtonIcon onClick={(e) => handleReactionPicker(e,message.id)} iconType="cheer" aria-label="React" /></>}
                    </div>
                    <div className='message-reactions'>
                      {message.reactions && message.reactions.map((reaction, index) => (
                        <span key={index} className='reaction'>{reaction}</span>
                      ))}
                    </div>
                </div>
            ))}
            {userIsTyping && <div style={{marginBottom: '20px;'}}><EuiText size="s" style={{marginLeft: '10px'}}>Another user is typing...</EuiText></div>}
            <div ref={messagesEndRef} style={{margin: '20px'}}/> {/* This element will be scrolled into view */}
          </EuiListGroup>

          <div ref={messageFormContainerRef} className="message-form-container">
            <EuiForm component="form" onSubmit={handleSend} className="message-form full-width">
              <EuiFlexGroup className='full-width'>
                <EuiFlexItem grow={true} className='full-width'>
                  <EuiFormRow className='full-width'>
                    <>
                    <EuiTextArea
                      /*ref={inputRef}*/
                      id="input"
                      placeholder="Type a message..."
                      rows={1}
                      value={input}
                      onChange={(e) => handleInputChange(e)}
                      className={isMobileDevice ? "input-mobile" : "" + ' full-width'}
                      disabled={!socket}
                      autoFocus
                      autoComplete='off'
                      autoCapitalize='off'
                      autoSave='off'
                      resize='none'
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          // Call your submit function here
                          handleSend(e);
                        }
                      }}
                    />
                    <div className="chat-button-holder">
                      {!isMobileDevice ? (
                      <>
                      {/* <EuiButton className="chat-buttons" type='button' onClick={() => setShowEmojiPicker(!showEmojiPicker)} >
                        😍
                      </EuiButton> <EuiButton className="chat-buttons" type='submit' disabled={!socket}>
                        Send
                      </EuiButton>*/}
                      <button type='button' className="chat-buttons middleButton" onClick={() => updateShowFileChooser(true)}>📂</button>
                      <button type='button' className="chat-buttons middleButton" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>😍</button>
                      <button type='submit' className="chat-buttons rightButton" disabled={!socket}>Send</button>
                      </> ) : (
                        <></>
                      )
                      }
                    </div>
                  </>
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
          </div>
        </div>

        {showEmojiPicker && <div className={isMobileDevice ? "emojiChooser-mobile" : "emojiChooser"}><Picker data={data} onEmojiSelect={addEmoji} /></div>}

        {showReactionPicker && <div id='reactionPicker'><Picker data={data} onEmojiSelect={addReaction} /></div>}

        {roomSettingsModal && <RoomSettings ws={ws} room={room}/>}

        {showPrivacy && <EuiModal onClose={() => setShowPrivacy(false)} initialFocus="[name=fileInput]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>Privacy Notice</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiText>
              <p>By using this service, you agree to the following:</p>
              <ul>
                <li>Users are responsible for their own behavior while using the chat tool. Any misuse, including but not limited to illegal activities, harassment, or spamming, is strictly prohibited.</li>
                <li>There is no guarantee that you are talking to who you think you are talking to. A clients username changes every time they connect to a chat.</li>
                <li>This service itself is potentially Ephemeral and it's owners / developers / investors could take the project offline at anytime with zero repercussions.</li>
                <li>Additional TOS can be found using the Terms of Service button below.</li>
              </ul>
              <p>By using this service, you understand to the following:</p>
              <ul>
                <li>Your messages are encrypted and ephemeral. No data is stored server-side.</li>
                <li>Messages are not stored after the session ends.</li>
                <li>Messages are not monitored or moderated.</li>
                <li>Messages are not censored.</li>
                <li>Messages are not filtered.</li>
                <li>Messages are not tracked.</li>
                <li>Messages are not stored in logs.</li>
                <li>Messages are not stored in databases.</li>
                <li>Messages are not stored in backups.</li>
                <li>Messages are not stored in caches.</li>
                <li>Messages are not stored in indexes.</li>
                <li>Messages are not stored in search engines.</li>
                <li>Messages are not stored in memory.</li>
                <li>Messages are not stored in cookies.</li>
                <li>Messages are not stored in local storage.</li>
                <li>Messages are not stored in session storage.</li>
                <li>Messages are not stored in browser history.</li>
                <li>Messages are not stored in referrer headers.</li>
                <li>Messages are not stored in server logs.</li>
                <li>Messages are not stored in error logs.</li>
                <li>Messages are not stored in access logs.</li>
                <li>Messages are not stored in security logs.</li>
                <li>Messages are not stored in audit logs.</li>
                <li>Messages are not stored in firewall logs.</li>
                <li>Messages are not stored in intrusion detection logs.</li>
                <li>Messages are not stored in intrusion prevention logs.</li>
                <li>Messages are not stored in web application firewall logs.</li>
                <li>Messages are not stored in network security logs.</li>
                <li>Messages are not stored in network traffic logs.</li>
                <li>Messages are not stored in network packet capture logs.</li>
                <li>Messages are not stored in network flow logs.</li>
                <li>Messages are not stored in network performance logs.</li>
                <li>Messages are not stored in network availability logs.</li>
                <li>Messages are not stored in network reliability logs.</li>
                <li>Messages are not stored in network latency logs.</li>
                <li>Messages are not stored in network jitter logs.</li>
                <li>Messages are not stored in network bandwidth logs.</li>
                <li>Messages are not stored in network throughput logs.</li>
                <li>Messages are not stored in network utilization logs.</li>
                <li>Messages are not stored in network capacity logs.</li>
                <li>Messages are not stored in network congestion logs.</li>
                <li>Messages are not stored in network error logs.</li>
                <li>Messages are not stored in network warning logs.</li>
                <li>Messages are not stored in network information logs.</li>
                <li>Messages are not stored in network debug logs.</li>
                <li>Messages are not stored in network trace logs.</li>
                <li>Messages are not stored in network event logs.</li>
                <li>Messages are not stored in network alarm logs.</li>
                <li>Messages are not stored in network notification logs.</li>
                <li>Messages are not stored in network alert logs.</li>
                <li>Messages are not stored in network incident logs.</li>
                <li>Messages are not stored in network problem logs.</li>
                <li>Messages are not stored in network issue logs.</li>
                <li>Messages are not stored in network ticket logs.</li>
                <li>Messages are not stored in network request logs.</li>
                <li>Messages are not stored in network response logs.</li>
                <li>Messages are not stored in network status logs.</li>
                <li>Messages are not stored in network health logs.</li>
                <li><strong>Messages are not stored. Period.</strong></li>
              </ul>
            </EuiText>
            <EuiSpacer />
            <center><EuiButton size="xs" style={{ }} onClick={() => {window.location.href='/tos'}}>Terms of Service</EuiButton> <EuiButton size="xs" style={{ }} onClick={handleShowPrivacy}>Ok, I get it.</EuiButton> {/* Acknowledge button */}</center>
          </EuiModalBody>
        </EuiModal>}

        {showFileChooser && <EuiModal onClose={() => updateShowFileChooser(false)} initialFocus="[name=fileInput]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>File Sharing</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>

            <EuiTable>
              <EuiTableHeader>
                <EuiTableHeaderCell>Download</EuiTableHeaderCell>
              </EuiTableHeader>
              <EuiTableBody>
              {downloads.length > 0 ? (
                downloads.map((download, index) => (
                  <EuiTableRow key={index}>
                    <EuiTableRowCell>
                      <a href={download.link} target="_blank" rel="noopener noreferrer" download={download.name}>
                        {download.name} ({download.size} bytes)
                      </a>
                    </EuiTableRowCell>
                  </EuiTableRow>
                ))
              ) : (
                <EuiTableRow>
                  <EuiTableRowCell>There are no downloads available.</EuiTableRowCell>
                </EuiTableRow>
              )}
              </EuiTableBody>
            </EuiTable>
            <EuiSpacer />
            <EuiForm component="form">
              <EuiFormRow label="Upload (10mb Max)" fullWidth>
                <input type="file" id="fileInput" name="fileInput" onChange={handleFileChange} />
              </EuiFormRow>
              <EuiSpacer />
              <EuiButton type="button" onClick={handleSendFile}>Send File</EuiButton>
            </EuiForm>
          </EuiModalBody>
        </EuiModal>}

    </>
  );
}

export default App;
