import React, { useState, useEffect } from 'react';
import config from '../config/config';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import QRCodeGenerator from '../components/QRCode'
import FAQ from '../components/Faq';
import roomSettings from '../components/roomSettings';

import {
  EuiModal,
  EuiModalBody,
  EuiSpacer,
  EuiFieldText,
  EuiText,
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiBadge,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlexItem,
  EuiFlexGroup
} from '@elastic/eui';

function DeviceDetector() {
  // Access the user agent string
  const userAgent = navigator.userAgent;

  // Define regular expressions for common mobile user agents
  const mobileUserAgentRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  // Check if the user agent matches a mobile device
  const isMobileDevice = mobileUserAgentRegex.test(userAgent);

  return isMobileDevice;
}

function Header(){
  const { pendingMessages, isMobileDevice, updateIsMobileDevice, chat, socket, chatUsers, chatSettings, updateRoomSettingsModal, updateShowFileChooser, page } = useGlobalContext();
  const [showCallout, setShowCallout] = useState(!Cookies.get('devack')); // Check if cookie is set
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [share, setShare] = useState(false);
  const [users, setUsers] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    console.log("Is Mobile", DeviceDetector());
    updateIsMobileDevice(DeviceDetector());
  },[]);

  useEffect(() => {
    handleTitleChange();
  },[chatSettings, chatUsers]);

  const handleTitleChange = () => {
    let roomName = '';
    let pending = '';
    console.log(chatSettings);
    if(chatSettings.roomName){
      roomName = chatSettings.roomName + " - ";
    }

    /*if(pendingMessages){
      pending = `(${pendingMessages}) `;
    }*/

    document.title = `${pending}${roomName}VNSH.Chat`;
  }

  const handleShare = (newShare) => {
    setShare(newShare);
  }

  const handleUsers = (newShare) => {
    setUsers(newShare);
  }

  const handleSettings = (newShare) => {
    updateRoomSettingsModal(newShare);
  }

  const openFlyout = () => {
    setIsFlyoutVisible(true);
  };
  
  const closeFlyout = () => {
    setIsFlyoutVisible(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied to clipboard');
    });
  };

    return (
        <>
        <EuiHeader>
          <EuiHeaderSectionItem border="right">
            <EuiHeaderLink href="/">
              <img alt="Vanishing Chat" style={{height: "32px", marginTop: "3px"}} src='/assets/images/vanishinglink128.gif' />
            </EuiHeaderLink>
            <EuiHeaderLink href="/">
                {config.app.name}
            </EuiHeaderLink>
          </EuiHeaderSectionItem>
    
          <EuiHeaderLinks>
            {chat ? (
              <>
                {socket ? (
                  <EuiHeaderLink onClick={() => handleUsers(true)}>
                    Who's Here ({chatUsers.length} / {chatSettings.roomLimit})
                  </EuiHeaderLink>
                ) : (null)}

                <EuiHeaderLink onClick={() => updateShowFileChooser(true)}>
                  Files
                </EuiHeaderLink>
                <EuiHeaderLink onClick={() => handleSettings(true)}>
                  Settings
                </EuiHeaderLink>
                <EuiHeaderLink onClick={() => handleShare(true)}>
                  Invite
                </EuiHeaderLink>
                <EuiHeaderLink href="/">
                  Close Chat
                </EuiHeaderLink>
              </>
            ) : (
              <>
                <EuiHeaderLink target={"_blank"} href="https://vnsh.link">
                  Ephemeral Links
                </EuiHeaderLink>
                <EuiHeaderLink target={"_blank"} href="https://vnsh.net">
                  Ephemeral Inbox
                </EuiHeaderLink>
              </>
            )}
            {!socket && chat ? (
              <>
                <EuiHeaderLink onClick={() => window.location.reload()}>
                  Reconnect
                </EuiHeaderLink>
              </>
            ) : (null)}
              <EuiHeaderLink onClick={() => openFlyout()}>
                FAQ
              </EuiHeaderLink>
          </EuiHeaderLinks>
        </EuiHeader>

        {isFlyoutVisible && (
          <EuiFlyout
            ownFocus
            onClose={closeFlyout}
            aria-labelledby="helpFlyoutTitle"
            size="m"
          >
            <EuiFlyoutBody>
              <EuiText>
                <FAQ />
              </EuiText>
            </EuiFlyoutBody>
          </EuiFlyout>
        )}

        {share ? (
          <EuiModal onClose={() => handleShare(false)}>
          <EuiModalBody>
            <EuiText textAlign="center">
              <h2>Invite Others</h2>
              <p>Share this QR Code to invite others to this chat room.</p>
            </EuiText>
            <EuiSpacer size="m" />
            <EuiFieldText
              value={window.location.href}
              readOnly
              fullWidth
              onClick={(e) => copyToClipboard(e.target.value)}
            />
            <EuiSpacer size="m" />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <QRCodeGenerator text={window.location.href} />
            </div>
            <EuiSpacer size="m" />
            <EuiText textAlign="center">
              <p>Sharing does not allow new members access to any of the current chat.</p>
            </EuiText>
          </EuiModalBody>
        </EuiModal>
        ): null}

        {users ? (
          <EuiModal onClose={() => handleUsers(false)}>
            <EuiModalBody>
              <>
              <EuiText textAlign="center">
                <h2>Current Connected Users</h2>
              </EuiText>
              <EuiSpacer size="m" />
              <EuiFlexGroup wrap responsive={false} gutterSize="xs">
                <EuiFlexItem grow={false}>
                  {chatUsers.map((user, index) => (
                    <div key={index} title={user}>
                      <EuiBadge color="success">
                        {user}
                      </EuiBadge>
                    </div>
                  ))}
                </EuiFlexItem>
              </EuiFlexGroup>
                
              </>
            </EuiModalBody>
        </EuiModal>
        ): null}
        </>
      );
}

export default Header;