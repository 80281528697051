import React from 'react';
import {
    EuiButton,
  } from '@elastic/eui';

const BuyMeABeerButton = () => {
  const handleBuyBeerClick = () => {
    // Open a new tab with the donation link
    window.open('https://www.paypal.me/regli', '_blank');
  };

  return (
    <EuiButton onClick={handleBuyBeerClick}>
      <img src="/assets/images/beer-icon.png" alt="Beer Icon" width="24" height="24" style={{float: 'left', margin: 'auto'}} /> Buy me a beer
    </EuiButton>
  );
};

export default BuyMeABeerButton;