// GlobalContext.js
import React, { createContext, useContext, useState } from 'react';

// Create the context
const GlobalContext = createContext();

// Create the context provider
export const GlobalProvider = ({ children }) => {
  // Define your state variables here
  const [userData, setUserData] = useState({});
  const [isLoadingUserData, setIsLoadingUserData ] = useState(true);
  const [isMobileDevice, setIsMobileDevice ] = useState(false);
  const [variable1, setVariable1] = useState('');
  const [variable2, setVariable2] = useState('');
  const [chat, setChat] = useState('');
  const [chatUsers, setChatUsers] = useState([]);
  const [socket, setSocket] = useState(null);
  const [userGUID, setUserGUID] = useState(uuidv4());
  const [chatSettings, setChatSettings] = useState({});
  const [roomSettingsModal, setRoomSettingsModal] = useState(false);
  const [pendingMessages, setPendingMessages] = useState(0)
  const [showFileChooser, setShowFileChooser] = useState(false);
  const [page, setPage] = useState("");

  // Create functions to update the variables
  const updateUserData = (newValue) => {
    setUserData(newValue);
  };

  const updateVariable1 = (newValue) => {
    setVariable1(newValue);
  };

  const updateVariable2 = (newValue) => {
    setVariable2(newValue);
  };

  const updateIsLoadingUserData = (newValue) => {
    setIsLoadingUserData(newValue)
  }

  const updateIsMobileDevice = (newValue) => {
    setIsMobileDevice(newValue)
  }

  const updateChat = (newValue) => {
    setChat(newValue);
  };

  const updateChatUsers = (newValue) => {
    setChatUsers(newValue);
  }

  const updateSocket = (newValue) => {
    setSocket(newValue);
  }

  const updateUserGUID = (newValue) => {
    setUserGUID(newValue);
    //localStorage.setItem('userGUID', newValue);
  };

  const updateChatSettings = (newValue) => {
    setChatSettings(newValue);
  };

  const updateRoomSettingsModal = (newValue) => {
    setRoomSettingsModal(newValue);
  }

  const updatePendingMessages = (newValue) => {
    setPendingMessages(newValue);
  }

  const updateShowFileChooser = (newValue) => {
    setShowFileChooser(newValue);
  }

  const updatePage = (newValue) => {
    setPage(newValue);
  } 

  // Define the context value with state and functions
  const contextValue = {
    userData,
    variable1,
    variable2,
    isLoadingUserData,
    isMobileDevice,
    chat,
    chatUsers,
    socket,
    userData,
    userGUID,
    chatSettings,
    roomSettingsModal,
    pendingMessages,
    showFileChooser,
    page,
    updateUserData,
    updateVariable1,
    updateVariable2,
    updateIsLoadingUserData,
    updateIsMobileDevice,
    updateChat,
    updateChatUsers,
    updateSocket,
    updateUserGUID,
    updateChatSettings,
    updateRoomSettingsModal,
    updatePendingMessages,
    updateShowFileChooser,
    updatePage
  };

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

// Create a custom hook to access the context
export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
