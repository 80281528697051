import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import config from '../config/config';
import Cookies from 'js-cookie';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import QRCodeGenerator from '../components/QRCode'

import {
  EuiSpacer,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiButton,
  EuiCallOut,
  EuiSelect,
  EuiCheckbox,
  EuiTextArea
} from '@elastic/eui';
import TermsOfService from '../components/Tos';

function Home() {
  

  return (
    <>
    <Header />
        <TermsOfService />
    <Footer />
    </>
  );
}

export default Home;
