const config = {
    app: {
        name: process.env.APPNAME || "Vanishing Chat",
        title: process.env.APPTITLE || "VNSH.chat - Ephemeral Chat Tool",
        shortName: process.env.APPSHORTNAME || "Vanishing Chat",
        tagline: process.env.APPTAGLINE || "",
        baseDomain: process.env.APPBASEDOMAIN || "VNSH.chat",
        version: process.env.version || "v1.1.0rc3"
    },
    restrictions: {
        blockedKeys: ["admin","wp-admin","administrator","login","delete","logincallback","docs","help","donate","support","changelog","report","dmca","home","index","http","html","aspx"]
    },
    developer: {
        name: "Ron Egli",
        github: "https://github.com/smugzombie"
    },
    encryption: {
        iv: "2,235,46,160,125,167,63,53,42,74,131,85"
    }
  };
  
  export default config;