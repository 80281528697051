import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { EuiProvider } from '@elastic/eui'
import '@elastic/eui/dist/eui_theme_dark.css'
import Home from './pages/Home';
import config from './config/config';
import Chat from './pages/Chat';
import { GlobalProvider } from './components/Global';
import Tos from './pages/Tos';

function App() {

  return (
    <>
    <GlobalProvider>
      <EuiProvider colorMode={'dark'} >
        <Router>
          <Routes>
            <Route path="/" exact element={<Home/>} />
            <Route path="/tos" exact element={<Tos/>} />
            <Route path=":thiskey" element={<Chat />} />
          </Routes>    
        </Router>
      </EuiProvider>
    </GlobalProvider>
    </>
  );
}

export default App;
