import BuyMeABeerButton from '../components/Buymeabeer';
import { EuiText, EuiSpacer, EuiLink
} from '@elastic/eui';
export default function FAQ() {

    const faqData = [
      {
        question: 'What is this tool?',
        answer:
          'This tool is a follow-up to my other tool VNSH.Link. While I love the ability to easily share data securely between devices using a one time link, sometimes there needs to be a conversation around the data. This tool allows you to share a link to an encrypted chat room that is ephemeral and secure.',
      },
      {
        question: 'Why does this tool exist?',
        answer:
          'This tool exists to address the need for a secure and ephemeral way to transmit sensitive information. It ensures that the shared data is not stored indefinitely, reducing the risk of unauthorized access.',
      },
      {
        question: 'Why did YOU create this tool?',
        answer:
          'I created this tool to help people share information securely and privately. I believe that privacy is a fundamental human right and that everyone should have the ability to communicate securely. I hope that this tool helps people to communicate more freely and securely.',
      },
      {
        question: 'Where is the security?',
        answer : 'By default chats and chat rooms are automatically encrypted browserside. The encryption key is part of the link and is never stored by the server. This means that even though the data temporarily passes through our servers we cannot see the contents.',
      },
      {
        question: 'How can I buy you a beer?',
        answer:
          <EuiText><strong>Anything</strong> is appreciated to keep projects like this alive and updated. If you want to buy me a beer, a case, or just help with hosting this tool click the button below! <br></br> <center><BuyMeABeerButton /></center></EuiText>,
      },

      // Add more FAQ items as needed
    ];
  
    return (
      <div>
        <h2>Frequently Asked Questions</h2>
        <EuiText>
          <hr />
          <h3>
            What is this tool?
          </h3>
          <h4>
            This tool is a follow-up to my other tool <EuiLink target='_blank' href='https://vnsh.link'>VNSH.Link</EuiLink>. While I love the ability to easily share data securely between devices using a one time link, sometimes there needs to be a conversation around the data. This tool allows you to share a link to an encrypted chat room that is ephemeral and secure.          </h4>
          <h3>
            Why does this tool exist?
          </h3>
          <h4>
            This tool exists to address the need for a secure and ephemeral way to transmit sensitive information. It ensures that the shared data is not stored indefinitely, reducing the risk of unauthorized access.
          </h4>
          <h3>
            Where is the security?
          </h3>
          <h4>
            By default chats and chat rooms are automatically encrypted browserside. The encryption key is part of the link and is never stored by the server. This means that even though the data temporarily passes through our servers we cannot see the contents.
          </h4>
          <h3>
            How can I buy you a beer?
          </h3>
          <h4>
            <strong>Anything</strong> is appreciated to keep projects like this alive and updated. If you want to buy me a beer, a case, or just help with hosting this tool click the button below! <br></br> <center><BuyMeABeerButton /></center>
          </h4>

        </EuiText>
      </div>
    );
  }