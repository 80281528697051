import React from 'react';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import {
    EuiButton, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiModalFooter, EuiButtonEmpty, EuiButtonIcon, EuiFieldText, EuiFormRow, EuiSpacer, EuiText, EuiLink, EuiFlexGroup, EuiFlexItem, EuiCheckbox, EuiSwitch, EuiFieldNumber, EuiSelect, EuiFieldPassword, EuiFilePicker, EuiCallOut, EuiCodeBlock, EuiCode,
  } from '@elastic/eui';

const RoomSettings = (props) => {
  const { chatSettings, updateRoomSettingsModal } = useGlobalContext();
  const {ws, room} = props;

  const updateSetting = async (setting, value) => {
    let command = `${setting} ${value}`;
    ws.current.send(JSON.stringify({ type: 'command', id: "blah", message: btoa(command) }));
  };

  return (
    <>
    <EuiModal onClose={() => updateRoomSettingsModal(false)}>
        <EuiModalHeader>
            <EuiModalHeaderTitle>Chat Room Settings</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
            <EuiFormRow label="Room Name" helpText="The name of the room.">
                <EuiFieldText 
                    value={chatSettings.roomName} 
                    placeholder='Purpose of this room'
                    onChange={e => {
                        const value = e.target.value;
                          updateSetting('roomName', value);
                      }}
                />
            </EuiFormRow>
            <EuiFormRow label="Room Capacity" helpText="The maximum number of users allowed in the room.">
                <EuiFieldNumber 
                    value={chatSettings.roomLimit} 
                    onChange={e => {
                        const value = e.target.value;
                        if (!isNaN(value)) {
                          updateSetting('roomLimit', value);
                        } else {
                          console.error('Invalid input: roomLimit must be a number');
                        }
                      }}
                />
            </EuiFormRow>
            <EuiFormRow label="Font Size" helpText="Select a font size.">
            <EuiSelect
                options={[
                { value: '12pt', text: '12' },
                { value: '14pt', text: '14' },
                { value: '16pt', text: '16' },
                { value: '18pt', text: '18' },
                { value: '20pt', text: '20' },
                // Add more options as needed
                ]}
                value={chatSettings.fontSize}
                onChange={e => updateSetting('fontSize', e.target.value)}
            />
            </EuiFormRow>
            <EuiFormRow label="Background Color" helpText="Select a background color.">
            <EuiSelect
                value={chatSettings.backgroundColor}
                options={[
                { value: 'red', text: 'Red' },
                { value: 'blue', text: 'Blue' },
                { value: 'green', text: 'Green' },
                { value: 'yellow', text: 'Yellow' },
                { value: 'black', text: 'Black' },
                // Add more options as needed
                ]}
                onChange={e => updateSetting('backgroundColor', e.target.value)}
            />
            </EuiFormRow>
            { /* <EuiFormRow label="Auto Blur Messages" helpText="">
            <EuiSelect
                value={chatSettings.autoBlur}
                options={[
                { value: 'false', text: 'Off' },
                { value: 'true', text: 'On' },
                ]}
                onChange={e => updateSetting('autoBlur', e.target.value)}
            />
            </EuiFormRow>
            <EuiFormRow label="Auto Blur Timeout" helpText="">
            <EuiSelect
                value={chatSettings.blurTime}
                options={[
                { value: '10', text: '10 Seconds' },
                { value: '20', text: '20 Seconds' },
                { value: '30', text: '30 Seconds' },
                { value: '60', text: '1 Minute' },
                { value: '120', text: '2 Minutes' },
                // Add more options as needed
                ]}
                onChange={e => updateSetting('blurTime', e.target.value)}
            /> 
            </EuiFormRow>*/ }

        </EuiModalBody>
        <EuiModalFooter>
            <EuiButtonEmpty onClick={() => updateRoomSettingsModal(false)}>Close</EuiButtonEmpty>
        </EuiModalFooter>
    </EuiModal>
    </>
    
  );
};

export default RoomSettings;