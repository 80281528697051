import React from 'react';
import {
  EuiPageTemplate,
  EuiPageHeader,
  EuiPanel,
  EuiTitle,
  EuiText,
  EuiSpacer,
} from '@elastic/eui';

const TermsOfService = () => {
  return (
    <EuiPageTemplate>
      <EuiPageHeader
        pageTitle="Terms of Service"
        description="Please read these terms of service carefully before using our encrypted ephemeral chat tool."
      />
      <EuiPanel paddingSize="l">
        <EuiTitle>
          <h2>1. Introduction</h2>
        </EuiTitle>
        <EuiText>
          <p>
            Welcome to our encrypted ephemeral chat tool. By accessing or using our service, you agree to be bound by these terms of service.
          </p>
        </EuiText>
        <EuiSpacer size="l" />

        <EuiTitle>
          <h2>2. Description of Service</h2>
        </EuiTitle>
        <EuiText>
          <p>
            Our service provides encrypted ephemeral chat with ephemeral identities. No data is stored server-side, and no two sessions involve the same usernames. Each session, all users get their own unique username. No login is required; just visit a direct link to a chat room to begin chatting.
          </p>
        </EuiText>
        <EuiSpacer size="l" />

        <EuiTitle>
          <h2>3. User Responsibilities</h2>
        </EuiTitle>
        <EuiText>
          <p>
            Users are responsible for their own behavior while using the chat tool. Any misuse, including but not limited to illegal activities, harassment, or spamming, is strictly prohibited.
          </p>
        </EuiText>
        <EuiSpacer size="l" />

        <EuiTitle>
          <h2>4. No Storage of Data</h2>
        </EuiTitle>
        <EuiText>
          <p>
            We do not store any data on our servers. All messages and user information are ephemeral and are not retained after the session ends.
          </p>
        </EuiText>
        <EuiSpacer size="l" />

        <EuiTitle>
          <h2>5. Changes to Terms</h2>
        </EuiTitle>
        <EuiText>
          <p>
            We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting the revised terms.
          </p>
        </EuiText>
        <EuiSpacer size="l" />

        <EuiTitle>
          <h2>6. Contact Information</h2>
        </EuiTitle>
        <EuiText>
          <p>
            If you have any questions about these terms, please contact us at support [at] vnsh.link.
          </p>
        </EuiText>
      </EuiPanel>
    </EuiPageTemplate>
  );
};

export default TermsOfService;
