import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import config from '../config/config';
import Cookies from 'js-cookie';
import { useGlobalContext } from '../components/Global'; // Import your GlobalProvider
import QRCodeGenerator from '../components/QRCode'
import { encodedFormData, decodedFormData, generateEncryptionKey, generateShortEncryptionKey, encryptData, decryptData } from '../components/Encryption';

import {
  EuiSpacer,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiButton,
  EuiCallOut,
  EuiSelect,
  EuiCheckbox,
  EuiTextArea
} from '@elastic/eui';

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

function Home() {
  const { userData, isMobileDevice } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")
  const [link, setLink] = useState("");
  const [key, setKey] = useState("");
  const [secure, setSecure] = useState(false);
  const [copyText, setCopyText] = useState("Copy");
  const [showIntro, setShowIntro] = useState(!Cookies.get('introack')); // Check if cookie is set
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [encryption, setEncryption] = useState(true);
  const [encryptionKey, setEncryptionKey] = useState(generateEncryptionKey(128));
  const fileInputRef = useRef(null);

  const handleIntroAcknowledgement = () => {
    Cookies.set('introack', 'true', { expires: 365 }); // Set the cookie to acknowledge the warning
    setShowIntro(false); // Hide the callout after acknowledgment
  };

  const [formData, setFormData] = useState({
    key: '',
    data_type: 'note', // Default data_type is 'note'
    data: '',
    secure: false,
  });

  useEffect(() => {
    if(isMobileDevice){
      setCopyText("Share")
    }else{
      setCopyText("Copy")
    }
  }, [isMobileDevice]);

  const handleChange = (e) => {
    setKey(e.target.value);
  };

  const joinRoom = () => { 

    if(key === ""){
      setError("Please enter a key to join a room.")
      return;
    }

    window.location.href = `/${key}` + (encryption ? `#${encryptionKey}` : '');
  };

  const generateRandomKey = () => {
    let newKey = generateRandomString(10).toLowerCase();
    setKey(newKey)
    setFormData({
      ...formData,
      key: newKey
    });
  }

  const generateRandomEncryptionKey = () => {
    let newKey = generateShortEncryptionKey(128);
    setEncryptionKey(newKey)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied to clipboard');
    });
  };

  return (
    <>
    <Header />
    {showIntro && (
      <>
      <EuiCallOut
      size="s"
      title="Greetings!"
      iconType="help"
      >
        <center>
        <p>
          Vnsh.chat lets you share sensitive information online, End-to-end encrypted, One time. Keep confidential information out monitored communication channels and history. All chats are encrypted and ephemeral. No data is stored server-side, and no two sessions involve the same usernames. 
        </p>
        <EuiButton size="xs" style={{ }} onClick={() => {window.location.href='/tos'}}>Terms of Service</EuiButton> <EuiButton size="xs" style={{ }} onClick={handleIntroAcknowledgement}>Hide Intro</EuiButton> {/* Acknowledge button */}</center>
      </EuiCallOut>
      </>
    )}
    <EuiSpacer size='l' />
    <EuiPanel style={{ width: 'auto', maxWidth: '600px', margin: 'auto' }}>
      <>
          {error ? (
            <EuiCallOut title="Whoops! That didn't work.." color="danger" iconType="alert">
              <p>
                {error}
              </p>
            </EuiCallOut>
          ) : null}
          <EuiFormRow label='Join a Chat Room' fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={true}>
                <EuiFieldText
                name='key'
                id='key'
                fullWidth
                value={key}
                onChange={(e) => handleChange(e)} // Pass the event directly to your handleChange function
                checked='false'
                placeholder='Type custom key or click Random'
                autoFocus
                append={
                  <>
                      <EuiButton size='m' type='button' onClick={generateRandomKey} style={{maxWidth: "150px"}}>
                        Random Room
                      </EuiButton>
                  </>
                }
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiFormRow label='Encryption Key' fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={true}>
                <EuiFieldText
                name='encryptionKey'
                id='encryptionKey'
                fullWidth
                value={encryptionKey}
                //onChange={(e) => handleChange(e)} // Pass the event directly to your handleChange function
                checked='false'
                placeholder='Type custom key or click Random'
                readOnly={true}
                append={
                  <>
                      <EuiButton size='m' type='button' onClick={generateRandomEncryptionKey} style={{maxWidth: "150px"}}>
                        Random Key
                      </EuiButton>
                  </>
                }
                />
            </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup justifyContent="center">
              <EuiFlexItem grow={true}>
                <EuiButton 
                  type='button' 
                  onClick={joinRoom}
                  disabled={!key}  
                >
                  Join Room
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        {key ? (
            <>
            <EuiSpacer size='l' />
            <EuiFormRow label='Share this link to invite others' fullWidth>
              
              <>
              <EuiSpacer size='s' />
                <center>
                <EuiFieldText
                  value={window.location.href + key + (encryption ? `#${encryptionKey}` : '')}
                  readOnly
                  fullWidth
                  onClick={(e) => copyToClipboard(e.target.value)}
                />
                <EuiSpacer size='l' />
                <QRCodeGenerator text={window.location.href + key + (encryption ? `#${encryptionKey}` : '')} />
                </center>
              </>
            </EuiFormRow>
            </>
          ) : null}
      </>
    </EuiPanel>
    <EuiSpacer size='l' />
    <center><p><a href='/tos'>Terms of Service</a></p></center>
    <Footer />
    </>
  );
}

export default Home;
